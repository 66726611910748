<style>
.analysis-table button{min-width: 120px;font-size: 12px;position: relative;}
.analysis-table button .arrow{position: absolute;top: -3px;font-size: 18px!important;right: 4px;}
.v-application--is-ltr .v-input__prepend-outer {margin-right: 0px !important;margin-left: 8px !important;} 
</style>
<template>
<div>
<v-row class="ma-0">
    <v-col class="pa-2">
          <v-card>
            <div class="pa-1 d-flex justify-space-around">
            <v-row class="ma-0 align-center" width="fit-content">    
              <v-col cols="2" class="pa-0">
              <v-autocomplete label="Instrument" height="40" v-model="instrument" :items="symbolsList"
              class="search-area subtitle-2 d-flex align-center" hide-no-data hide-details solo flat dense
              prepend-icon="mdi-magnify" placeholder="Search...ex:Bank Nifty"></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pa-0"><v-autocomplete height="40" v-model="expirydate" :items="expiryList"
                class="search-area subtitle-2 pt-0 pl-4 mt-0" hide-no-data hide-details solo flat dense
                placeholder="Expiry Date"></v-autocomplete></v-col>
                <v-col cols="2" class="pa-0"><v-autocomplete height="40" v-model="strikeprice" :items="strickPriceList"
                class="search-area subtitle-2 pt-0 pl-4 mt-0" hide-no-data hide-details solo flat dense
                placeholder="Strike Price"></v-autocomplete></v-col>
                <v-col cols="2" class="pa-0"><v-autocomplete height="40" v-model="timeInterval" :items="timeinterval"
                class="search-area subtitle-2 pt-0 pl-4 mt-0" hide-no-data hide-details solo flat dense
                placeholder="Time Interval"></v-autocomplete></v-col>
             <v-col class="d-flex justify-space-between">
                <!-- <span class="verticalline"></span> -->
                <!-- <span class="caption">Expiry</span> -->
                <!-- <span class="verticalline"></span> -->
                <!-- <v-divider vertical></v-divider> -->
                <span class="caption">ATM IV<span class="ml-2">0.05%</span></span>
                <!-- <span class="verticalline"></span> -->
                <v-divider vertical></v-divider>
                <span class="caption">IV Chart</span>
                <!-- <span class="verticalline"></span> -->
                <v-divider vertical></v-divider>
                <a href="#/option/optionchain/0" class="caption">Option Chain</a>
                </v-col>
                <v-col></v-col>
            </v-row>
             </div>
          </v-card>
    </v-col>
</v-row>

   <v-simple-table class="mx-2 analysis-table f-semibold a-box">
      <thead>
        <tr>
          <th class="text-center">Time</th>
          <th class="text-right">Call OI</th>
          <th class="text-right">Call OI Change</th>
          <th class="text-center">Call D. H/L Break</th>
          <th class="text-right">Call LTP</th>
          <th class="text-center">Call OI Interpretation</th>
          <th class="text-center strike">Strike</th>
          <th class="text-center">Put OI Interpretation</th>
          <th class="text-right">Put LTP</th>
          <th class="text-center">Put D. H/L Break</th>
          <th class="text-right">Put OI</th>
          <th class="text-right">Put OI Change</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(item, index) in oiAnalysisTableData" :key="index">
          <td>{{ item.time }}</td>
           <td class="text-right"> {{ item.callOi }}</td>
          <td class="text-right"> {{ item.callOi }}
            <div class="py-1 fsize12"><span class="ml-1" :class="parseFloat(item.callOiPer) > 0 ? 'positivevalue' : 'negativevalue'">({{item.callOiPer}}%)</span></div>
          </td>
          <td><button class="white--text font-weight-bold rounded" :class="item.breakOiCall != '' ? 'btnRedClr': ''">{{ item.breakOiCall }}</button></td>
          <td class="text-right py-2">{{ item.callLtp }}
            <div class="py-1 fsize12"><span :class="parseFloat(item.callLtpChange) > 0 ? 'positivevalue' : 'negativevalue'">{{item.callLtpChange}}</span> <span class="ml-1" :class="parseFloat(item.callLtpPer) > 0 ? 'positivevalue' : 'negativevalue'">({{item.callLtpPer}}%)</span></div>
          </td>
          <td>
            <button
              class="white--text font-weight-bold px-2 rounded" :class="item.callInterpretation == 'Long Build Up' ? 'btnGreenClr' : item.callInterpretation == 'Short Build Up' ? 'btnRedClr' : item.callInterpretation == 'Long Unwinding' ? 'btnOrangeClr' : item.callInterpretation == 'Short Covering' ? 'btnBlueClr' : ''">
              {{ item.callInterpretation }}
            </button>
          </td>
          <td class="strike font-weight-bold">{{ item.strike }}</td>
          <td class="pa-2">
            <button
              class="white--text font-weight-bold px-2 rounded" :class="item.putInterpretation == 'Long Build Up' ? 'btnGreenClr' : item.putInterpretation == 'Short Build Up' ? 'btnRedClr' : item.putInterpretation == 'Long Unwinding' ? 'btnOrangeClr' : item.putInterpretation == 'Short Covering' ? 'btnBlueClr' : '' ">
              {{ item.putInterpretation }}
              
            </button>
          </td>
          <td class="text-right">{{ item.putLtp }}
            <div class="py-1 fsize12"><span :class="parseFloat(item.putLtpChange) > 0 ? 'positivevalue' : 'negativevalue'">{{item.putLtpChange}}</span> <span class="ml-1" :class="parseFloat(item.putLtpPer) > 0 ? 'positivevalue' : 'negativevalue'">({{item.putLtpPer}}%)</span></div>
          </td>
          <td><button class="white--text font-weight-bold rounded" :class="item.breakOiPut != '' ? 'btnRedClr': ''">{{ item.breakOiPut }}</button></td>
           <td class="text-right py-2">{{ item.putOi }}</td>
          <td class="text-right py-2">{{ item.putOi }}
            <div class="py-1 fsize12"><span class="ml-1" :class="parseFloat(item.putOiPer) > 0 ? 'positivevalue' : 'negativevalue'">({{item.putOiPer}}%)</span></div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
</div>
</template>

<script>
import { mapState} from "vuex"
import commonFunctions from "@/mixins/commonFunctions"
export default {
  mixins: [commonFunctions],
  data:()=> ({
    instrument:'BANKNIFTY',
    expirydate:'25NOV21',
    strikeprice:'',
    timeInterval:'1MIN',
    timeinterval:['1MIN'],
    oiAnalysisTableData: [
        { time: "12:40-12:45", callOi: "10,74,500",breakOiPut:'D.L.B (66.00)',breakOiCall:'D.L.B (0.25)', callOiChange: "35.98", callOiPer: "-14.09", callLtpChange: "40.08", callLtpPer: "12.08",callBreak: "", callLtp: "281.95", callInterpretation: "Long Build Up", strike: "36300", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",breakOiPut:'',breakOiCall:'', callOiChange: "-35.98", callOiPer: "14.09", callLtpChange: "-40.08", callLtpPer: "12.08",callBreak: "", callLtp: "281.95", callInterpretation: "Long Build Up", strike: "36300", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "-12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",breakOiPut:'D.L.B (30.35) ',breakOiCall:'', callOiChange: "35.98", callOiPer: "14.09", callLtpChange: "40.08", callLtpPer: "-12.08",callBreak: "", callLtp: "281.95", callInterpretation: "Short Covering", strike: "36300", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",breakOiPut:'',breakOiCall:'D.L.B (0.25)', callOiChange: "35.98", callOiPer: "-14.09", callLtpChange: "40.08", callLtpPer: "12.08",callBreak: "", callLtp: "281.95", callInterpretation: "Long Build Up", strike: "36300", putInterpretation: "Long Unwinding", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
      ],
  }),

  computed:{
     ...mapState('symbols',['symbolsList','expiryList','strickPriceList','currentStrikePrice','optionList']),
  },

  methods: {
    async initialFunctions() {
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
      await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
      this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
      this.strikeprice = await this.getCurrentPrice(this.currentStrikePrice)
      console.log(this.strikeprice)
    }
  },

  created() {
    this.initialFunctions()
  }
}
</script>

<style>

</style>